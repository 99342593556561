/*
 * Responsibility:
 * Map a reactive object to the head via useHead composable.
 * Expose a function to parse/format/update the reactive object with yoast data.
 */

import { reactive, useHead } from '#imports'

export function useYoastData() {
  const metaData = reactive({})
  useHead(metaData)

  const formatYoastMeta = (meta) => {
    return meta.map((item) => {
      return { name: item.name || item.property, content: item.content }
    })
  }

  const injectYoastDataFrom = (title, meta) => {
    metaData.title = title
    metaData.meta = formatYoastMeta(meta)
  }

  return { injectYoastDataFrom }
}
